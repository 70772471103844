import { NavigationGuardNext } from "vue-router";
import _ from "lodash";

interface DetailPage {
  value: string;
  title: string;
  route: string;
}

interface DetailPagesList {
  [key: string]: [DetailPage];
}

export const platformRouteGuards: (
  routeToNavigate: string,
  baseRoutes: Array<string>,
  detailPages: DetailPagesList,
  listingPages: Array<string>,
  redirectionCb: NavigationGuardNext
) => void = (
  routeToNavigate,
  baseRoutes,
  detailPages,
  listingPages,
  redirectionCb
) => {
  const pageDetailKeys: Array<string> = _.keys(detailPages);
  const detailPagesAvailable: Array<string> = pageDetailKeys
    .reduce((total, current) => {
      const pageObject = _.cloneDeep(detailPages[current]);
      return total.concat(pageObject);
    }, [] as Array<DetailPage>)
    .map((item) => item?.route);

  const allAvailablePages: Array<string> = baseRoutes
    .concat(listingPages)
    .concat(pageDetailKeys)
    .concat(detailPagesAvailable);

  if (!allAvailablePages.includes(routeToNavigate)) {
    redirectionCb();
    return;
  }
  return;
};
