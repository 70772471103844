const states = {
  inelligible: {
    value: "inelligible",
    label: "Inelligible",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 1,
  },
  wip: {
    value: "wip",
    label: "WIP (work in progress application)",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 2,
  },
  applied: {
    value: "applied",
    label: "Applied",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 3,
  },
  needsEvaluation: {
    value: "needsEvaluation",
    label: "Needs Evaluation",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 4,
  },
  reportReady: {
    value: "reportReady",
    label: "Diagnostic Report Ready",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 5,
  },
  bdrReady: {
    value: "bdrReady",
    label: "BDR Ready",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 6,
  },
  unqualified: {
    value: "unqualified",
    label: "Unqualified",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 7,
  },
  dealPreparation: {
    value: "dealPreparation",
    label: "Deal Preparation",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 8,
  },
  investmentReady: {
    value: "investmentReady",
    label: "Investment Ready",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 9,
  },
  published: {
    value: "published",
    label: "Deal is Published",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 10,
  },
  match: {
    value: "match",
    label: "Match",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 11,
  },
  archived: {
    value: "archived",
    label: "Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 12,
  },
};

module.exports = states;
