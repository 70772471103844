export default {
  seekersList: {
    header: {
      title: "SMEs List",
      counter: "SMEs",
    },
    list: {
      displayName: "Organisation Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Business Support Applications",
      counter: "Applications",
    },
  },
  investorList: {
    header: {
      title: "Capital Providers List",
      counter: "Capital Providers",
    },
  },
  intermediaryList: {
    header: {
      title: "Service Providers List",
      counter: "Service Providers",
    },
  },
  matchList: {
    header: {
      title: "Deal Match List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Deal Match", subtitle: "Matched deals" },
      investor: { title: "Capital Providers", subtitle: "Application" },
      investee: {
        title: "Business Support Applications",
        subtitle: "Business Development",
      },
      intermediary: {
        title: "Service Providers",
        subtitle: "Application",
      },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to Applications",
      role: "Application",
    },
    healthReport: {
      accepted: "Diagnostic Report has been accepted",
      notAccepted: "Diagnostic Report has not yet been accepted",
      form: {
        uploadHR: {
          title: "Diagnostic Report",
          info: "Please attach the report for the SME to review in doc format, max 1Gb",
        },
        highlights: {
          title: "Diagnostic Report Findings Highlights",
        },
        keyImprovements: {
          title: "Business Development Areas",
          info: "Please select the relevant business development tags to indicate the gaps areas identified during the Diagnosis Review",
        },
        neededAreasOfExpertise: {
          title: "Service Provider Expertise Needed",
          info: "Please select the relevant business development tags to indicate the gaps areas identified during the Diagnosis Review",
        },
        postReviewNotes: {
          title: "Diagnostic Report Post-review Feedback Notes",
        },
        saveButton: "Save Changes",
        successMessage: "Form successfully saved.",
      },
      dealSupport: {
        title: "Business Support Assignments",
      },
    },
  },
  investorDetail: {
    header: {
      backButton: "Back to Capital Providers",
      role: "Capital Providers",
    },
    potentialDeals: {
      title: "Investee List",
      subtitle: "Investees",
      table: {
        companyName: "Company Name",
        country: "Country",
        sector: "Sector",
        leadEmail: "Exec email",
        action: "Action",
      },
    },
  },
  intermediaryDetail: {
    header: {
      backButton: "Back to Service Providers",
      role: "Service Providers",
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to SME's",
      role: "SME",
    },
  },
  bdrAcronym: "DR",
  bdrName: "Diagnostic Report",
  usersList: {
    header: {
      title: "Users List",
      counter: "Users",
    },
  },
  userDetail: {
    header: {
      backButton: "Back to Users",
    },
  },
  reassign: {
    header: "Business Support Application Reassignment",
    currentUser: {
      title: "Currently Assigned User",
      p1: "User acount that this submission is currently assigned to.",
      data: {
        name: "Name: ",
        email: "Email: ",
      },
    },
    search: {
      title: "Search Registered Applicant Accounts",
      p1: "Reassign this business support application to a new registered account by transferring access to its details on the public portal. Once reassigned, the previous account will lose access to the application and any associated details they had provided.",
      p2: "Use the search functionality below to find and select the desired registered applicant account. Note that only registered accounts can be assigned business support applications.",
      placeholder: "Search by Applicant Email",
      select: "Select Registered Account",
    },
    popup: {
      message:
        "Are you sure you want to reassign this business support application record? This action will transfer ownership to the selected registered account: ",
    },
  },
};
