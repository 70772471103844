
import { defineComponent, nextTick, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useMeta } from "vue-meta";
import { msalService } from "./config/useAuth";
import { msalInstance } from "./config/msal.config";
import packageJson from "../package.json";

export default defineComponent({
  name: "app",
  setup() {
    const { handleRedirect } = msalService();
    const store = useStore();

    const title = store.getters["platform/config"]?.appTitle;
    useMeta({
      title,
    });

    const isTokenChecked = computed(() => {
      return store.getters["tokens/isTokenChecked"];
    });

    const initialize = async () => {
      try {
        await msalInstance.initialize();
      } catch (error) {
        console.log("Initialization error", error);
      }
    };

    onMounted(async () => {
      console.log("Application loaded, version " + packageJson.version);
      const isTokenChecked = await store.getters["tokens/isTokenChecked"];
      if (isTokenChecked === false) {
        await initialize();
        await handleRedirect();
      }
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      await store.dispatch("user/checkAndRefresh");

      nextTick(() => {
        initializeComponents();
      });
    });
    return {
      isTokenChecked,
      title,
    };
  },
});
