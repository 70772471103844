const states = {
  application: {
    value: "application",
    label: "1 Application in progress",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 1,
  },
  applied: {
    value: "applied",
    label: "2 Applied - to review",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 2,
  },
  inPipeline: {
    value: "inPipeline",
    label: "3 In Pipeline",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 3,
  },
  rejected: {
    value: "rejected",
    label: "4 Rejected",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 4,
  },
  inWorkplanIncluded: {
    value: "inWorkplanIncluded",
    label: "5 Included in Workplan",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 5,
  },
  inWorkplan: {
    value: "inWorkplan",
    label: "6 Appraisal stage",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 6,
  },
  inWorkplanFunding: {
    value: "inWorkplanFunding",
    label: "7 Approved - Implementation",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 7,
  },
  inWorkplanFundingCompleted: {
    value: "inWorkplanFundingCompleted",
    label: "8 Implementation Completed",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 8,
  },
  projectClosed: {
    value: "projectClosed",
    label: "9 Project Closed",
    parent: "published",
    color: "pending",
    chartColor: "#009EF7",
    order: 9,
  },
  published: {
    value: "published",
    label: "10 Ready for investment",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 10,
  },
  funded: {
    value: "funded",
    label: "11 Fully/partially funded",
    parent: "published",
    color: "done",
    chartColor: "#009EF7",
    order: 11,
  },
  inPipelineReview: {
    value: "inPipelineReview",
    label: "12 In Pipeline - Review Due",
    parent: "new",
    color: "inProgress",
    chartColor: "#eaaf39",
    order: 12,
  },
  archived: {
    value: "archived",
    label: "13 Archived",
    parent: "closed",
    color: "denied",
    chartColor: "#c0c0c0",
    order: 13,
  },
};

module.exports = states;
