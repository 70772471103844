export default {
  seekersList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
    list: {
      displayName: "Company Name",
      country: "Country",
      leadName: "Lead name",
      leadEmail: "Lead email",
    },
  },
  investeeList: {
    header: {
      title: "Investee List",
      counter: "Investees",
    },
  },
  investorList: {
    header: {
      title: "Investors List",
      counter: "Investors",
    },
  },
  intermediaryList: {
    header: {
      title: "Intermediary List",
      counter: "Intermediaries",
    },
  },
  matchList: {
    header: {
      title: "Investor Interests List",
      counter: "interests",
    },
  },
  dashboard: {
    charts: {
      match: { title: "Investment Activity", subtitle: "Matched deals" },
      investor: { title: "Investors", subtitle: "Onboarding progress" },
      investee: { title: "Investees", subtitle: "Deal Progress" },
      intermediary: {
        title: "Intermediaries",
        subtitle: "Onboarding progress",
      },
    },
  },
  investeeDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
    healthReport: {
      accepted: "HR Accepted",
      notAccepted: "HR not accepted yet",
      form: {
        uploadHR: {
          title: "Healt Report",
          info: "Please attach BDR for this investee in doc. format, max size 1GB",
        },
        highlights: {
          title: "BDR highlights",
        },
        keyImprovements: {
          title: "Key improvements areas",
          info: "Please select the relevant BDR tag(s) to indicate the gap areas that were noted during the Business review.",
        },
        neededAreasOfExpertise: {
          title: "Need deal support expertise",
          info: "Please select the relevant option(s) to indicate the needed expertise support to get this proposal investor-ready",
        },
        postReviewNotes: {
          title: "BDR post-review feedback notes",
        },
        saveButton: "Save Changes",
        successMessage: "Form successfully saved.",
      },
      dealSupport: {
        title: "Deal Support Assignments",
      },
    },
    potentialInvestors: {
      active: "Active",
      closed: "Closed",
      abandoned: "Abandoned",
      emptyState: "Here you can see finished matches",
    },
  },
  investorDetail: {
    header: {
      backButton: "Back to investors",
      role: "investor",
    },
    potentialDeals: {
      title: "Investee List",
      subtitle: "Investees",
      table: {
        companyName: "Company Name",
        country: "Country",
        sector: "Sector",
        leadEmail: "Exec email",
        action: "Action",
      },
    },
  },
  intermediaryDetail: {
    header: {
      backButton: "Back to intermediaries",
      role: "intermediary",
    },
  },
  seekerDetail: {
    header: {
      backButton: "Back to investees",
      role: "Investee",
    },
  },
  bdrAcronym: "BDR",
  bdrName: "Business Diagnosis Report",
  reassign: {
    header: "Proposal reassignment",
    currentUser: {
      title: "Currently Assigned User",
      p1: "User acount that this submission is currently assigned to.",
      data: {
        name: "Name: ",
        email: "Email: ",
      },
    },
    search: {
      title: "Search Users List",
      p1: "To assign this proposal record to a different user, you must first search for the appropriate user from the list of registered users. Note that only registered users can be granted access to the proposal record.",
      p2: "",
      placeholder: "search by user email",
      select: "Select User",
    },
    popup: {
      message:
        "Are you sure you want to reassign this proposal? This action will transfer ownership of this proposal to ",
    },
  },
};
